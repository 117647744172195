@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Nunito', sans-serif;
 }
.typed-out{
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;
  animation: 
  typing 4s forwards;
  font-size: 1.6rem;
  width: 0;
}
#li::before {
  content: "\003e ";
  color: #22c55e;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
.li::before {
  content: "\2794 ";
  color: #22c55e
}
.li span {
  position: relative;
  left: 10px;
}

#mydiv{ animation: changeBg 20s infinite; min-height: 100vh; max-height: 100vh; }
@keyframes changeBg{
   0%,100%  {background-image: url("./assets/images/Banner1.jpg");}
   50% {background-image: url("./assets/images/Banner2.jpg");}
}

.sticky-header {
  position: sticky;
  top: -20px;
  padding: 20px;
  width: 100%;
  background: rgba(255,255,255);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #008CBA; */
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.box:hover .overlay {
  height: 100%;
}

.text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}